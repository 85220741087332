import { createSlice } from "@reduxjs/toolkit";

/*
 textItem: 
        {
            "itemId": 86875,
            "hindiValue": "राम",
            "fieldName": "FULL_NAME",
            "engValue": "ram",
            "updatedAt": "2024-09-19 13:52:05.701000"
        }

mediaItem:
        {
            "itemId": 86874,
            "fieldName": "PROFILE_IMAGE_URL",
            "url": "https://qa741.yadavahir.com/pics/wpipczxsgp20240919082205.png",
            "updatedAt": "2024-09-19 13:52:05.691000"
        }
*/
const assignmentSlice = createSlice({
  name: "assignment",
  initialState: {
    itemsAssigned: 0,
    assignmentId: "",
    textItems: [],
    mediaItems: [],
  },
  reducers: {
    assignmentPicked: (state, action) => {
      console.log("In assignmentSlice ");
      if (action.payload.itemsAssigned > 0) {
        state.assignmentId = action.payload.assignmentId;
        state.itemsAssigned = action.payload.itemsAssigned;
        if (
          action.payload.textItems != null &&
          action.payload.textItems.length > 0
        ) {
          state.textItems = action.payload.textItems;
        }
        if (
          action.payload.mediaItems != null &&
          action.payload.mediaItems.length > 0
        ) {
          state.mediaItems = action.payload.mediaItems;
        }
      }
    },
    assignmentSubmitted: (state, action) => {
      state.assignmentId = "";
      state.itemsAssigned = 0;
      state.textItems = [];
      state.mediaItems = [];
    },
  },
});

export const assignmentActions = assignmentSlice.actions; //actions similar to function names will be created like above object.
export default assignmentSlice.reducer;
