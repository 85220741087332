import { RouterProvider, createBrowserRouter } from "react-router-dom";

import { useEffect } from "react";
import { useDispatch } from "react-redux";
import getRouter from "./util/router";

function App() {
  useEffect(() => {
    console.log("App....do the one time task here.....");
  }, []);
  const dispatch = useDispatch();
  const browserRouter = createBrowserRouter(getRouter(dispatch));
  return <RouterProvider router={browserRouter} />;
}

export default App;
