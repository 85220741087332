import { Outlet } from "react-router-dom";
import MainNavigation from "../components/MainNavigation";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { loadState } from "../util/localStorageReader";
import { useDispatch } from "react-redux";
import { authActions } from "../store/reducers/authSlice";
import { getAuthToken } from "../util/auth";

function RootLayout() {
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  console.log("RootLayout, auth.isAuthenticated:", auth.isAuthenticated);
  let oldIsAuthenticated = false;
  const stateBeforeRefresh = loadState();
  if (stateBeforeRefresh.auth) {
    oldIsAuthenticated = stateBeforeRefresh.auth.isAuthenticated;
  }
  useEffect(() => {
    console.log(
      "Inside useEffect RootLayout, auth.isAuthenticated:",
      auth.isAuthenticated
    );
    console.log(
      "Inside useEffect RootLayout, OLDIsAuthenticated:",
      oldIsAuthenticated
    );
    if (oldIsAuthenticated && !auth.isAuthenticated) {
      dispatch(authActions.loginSuccess("abc"));
      authActions.loginSuccess(getAuthToken());
    }
  }, [auth.isAuthenticated, dispatch, oldIsAuthenticated]);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        // border: "2px solid lightgreen",
      }}
    >
      {(auth.isAuthenticated || oldIsAuthenticated) && <MainNavigation />}
      <main
        style={{
          display: "flex",
          flexDirection: "column",
          flexGrow: "1",
        }}
      >
        <Outlet />
      </main>
    </div>
  );
}

export default RootLayout;
