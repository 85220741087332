import { redirect } from "react-router-dom";

export function getAuthToken() {
  const token = localStorage.getItem("token");
  // console.log("checking token: ", token);
  return token;
}

export function keepOnLoginIfNotoken() {
  const token = getAuthToken();
  // debugger;
  // console.log("checking token during keepOnLoginIfNotoken loader=", token);
  if (!token) {
    console.log("redirecting to /login.");
    return redirect("/login");
  } else {
    return token;
  }
}

export function keepOnDashboardIfLoggedIn() {
  const token = getAuthToken();
  // debugger;
  console.log(
    "checking during keepOnDashboardIfLoggedIn loader, token =",
    token
  );
  if (token) {
    console.log("redirecting to /dashboard.");
    return redirect("/dashboard");
  } else {
    return token;
  }
}
export function keepOnDashboardOrLogin() {
  const token = getAuthToken();
  console.log("In keepOnDashboardOrLogin loader token=", token);
  if (token) {
    console.log("doing redirect to /dashboard");
    return redirect("/dashboard");
  } else {
    console.log("doing redirect to /login");
    return redirect("/login");
  }
}

export function doLogout(dispatch, authActions) {
  console.log("doing logout...............................");
  dispatch(authActions.logoutSuccess());
  localStorage.clear();
  return "logout";
}
