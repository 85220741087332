import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { authActions } from "../store/reducers/authSlice";
import { clearProfile } from "../store/reducers/userProfileSlice";

function Logout() {
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(authActions.logoutRequest());
    dispatch(clearProfile());
    localStorage.clear();
  }, [dispatch]);

  useEffect(() => {
    if (!isAuthenticated) {
      navigate("/");
    }
  }, [isAuthenticated, navigate]);
  return <>Logging out....</>;
}

export default Logout;

//todo during logout redux should be cleared
