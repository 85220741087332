import RootLayout from "../pages/RootLayout";
import LoginPage, { action as loginAction } from "../pages/LoginPage";
import ErrorPage from "../pages/ErrorPage";
import Logout from "../pages/Logout";
import Dashboard from "../pages/dashboard/Dashboard";
import {
  keepOnLoginIfNotoken,
  keepOnDashboardIfLoggedIn,
  keepOnDashboardOrLogin,
} from "./auth";

const getRouter = (dispatch) => {
  const router = [
    {
      path: "/",
      element: <RootLayout />,
      id: "root",
      errorElement: <ErrorPage />,
      children: [
        {
          index: true,
          loader: keepOnDashboardOrLogin, //no need of any child component just do redirect
        },
        {
          path: "/dashboard",
          element: <Dashboard />,
          loader: keepOnLoginIfNotoken,
        },
        {
          path: "/login",
          element: <LoginPage />,
          loader: keepOnDashboardIfLoggedIn,
          action: async (obj) => {
            await loginAction(obj.request, obj.params, dispatch);
          },
        },
        { path: "/logout", element: <Logout /> },
      ],
    },
  ];
  return router;
};
export default getRouter;
