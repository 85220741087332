import { useSelector } from "react-redux";
import styles from "./Dashboard.module.scss";
import commonStyles from "../../commonStyles.module.scss";
import { useEffect, useState } from "react";
import { Loader } from "../../components/UI/ErrorModal";
import TextItems from "./TextItems";
import MediaItems from "./MediaItems";
import { APPROVED_AFTER_EDIT, BASE_URL } from "../../Constants";
import { doLogout, getAuthToken } from "../../util/auth";
import { assignmentActions } from "../../store/reducers/assignmentSlice";
import { authActions } from "../../store/reducers/authSlice";
import { useDispatch } from "react-redux";
import { json, useNavigate } from "react-router-dom";

function Dashboard() {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const assignment = useSelector((state) => state.assignment);
  const [itemActionMap, setItemActionMap] = useState([]);
  const [isLoading, setIsLoading] = useState("");
  const navigate = useNavigate();
  console.log("On Dashboard: auth.isAuthenticated=", auth.isAuthenticated);
  useEffect(() => {
    console.log(
      "InUseEffect, On Dashboard: auth.isAuthenticated=",
      auth.isAuthenticated
    );
  }, [auth.isAuthenticated]);
  //------------------------------------------------------------------
  function updateActionOnItem(
    itemId,
    newAction,
    getEngValue = null,
    getHindiValue = null
  ) {
    //only during APPROVED_AFTER_EDIT we will get getEng/HindiValue methods
    //to read the edited values from input fields
    setItemActionMap((prevItemActionMap) => {
      const index = prevItemActionMap.findIndex(
        (item) => item.itemId === itemId
      );

      if (index !== -1) {
        const updatedItemActionMap = [...prevItemActionMap];
        updatedItemActionMap[index].action = newAction;
        updatedItemActionMap[index].getEngValue = getEngValue;
        updatedItemActionMap[index].getHindiValue = getHindiValue;
        return updatedItemActionMap;
      } else {
        return [
          ...prevItemActionMap,
          {
            itemId,
            action: newAction,
            getEngValue: getEngValue,
            getHindiValue: getHindiValue,
          },
        ];
      }
    });
  }
  //------------------------------------------------------------------
  async function callApiToFetchNewAssignment() {
    try {
      let url = BASE_URL + "/pick/pendingItems";
      const headers = {
        Authorization: `Bearer ${getAuthToken()}`,
        "Content-Type": "Application/json",
      };
      const response = await fetch(url, {
        headers,
      });
      if (!response.ok) {
        if (response.status === 403) {
          console.log("got 403....");
          doLogout(dispatch, authActions);
          navigate("/login");
        } else {
          throw json(
            {
              message: "Could not fetch pending items.",
            },
            {
              status: 500,
            }
          );
        }
      } else {
        console.log("got response ", response);
        const respData = await response.json();
        console.log("respData = ", respData);
        dispatch(assignmentActions.assignmentPicked(respData));
        if (respData.itemsAssigned === 0) {
          window.alert("No items found. Please try after sometime.");
        }
      }
    } catch (error) {
      window.alert("Error:", error);
    } finally {
      setIsLoading("");
    }
  }
  //------------------------------------------------------------------
  function getTextItemsToSubmit(itemsUpdated) {
    const convertedObjs = [];
    if (assignment.textItems.length > 0) {
      assignment.textItems.forEach((item, index) => {
        // console.log("checking index =", index);
        const itemUpdated = itemsUpdated.find(
          (it) => it.itemId === item.itemId
        );
        // console.log("itemUpdated = ", itemUpdated);

        if (itemUpdated) {
          convertedObjs.push({
            itemId: itemUpdated.itemId,
            engValue:
              itemUpdated.action === APPROVED_AFTER_EDIT
                ? itemUpdated.getEngValue()
                : item.engValue,
            hindiValue:
              itemUpdated.action === APPROVED_AFTER_EDIT
                ? itemUpdated.getHindiValue()
                : item.hindiValue,
            action: itemUpdated.action,
          });
        }
      });
    }
    return convertedObjs;
  }
  function getMediaItemsToSubmit(itemsUpdated) {
    const convertedObjs = [];
    if (assignment.mediaItems.length > 0) {
      assignment.mediaItems.forEach((item, index) => {
        // console.log("checking index =", index);
        const itemUpdated = itemsUpdated.find(
          (it) => it.itemId === item.itemId
        );
        // console.log("itemUpdated = ", itemUpdated);

        if (itemUpdated) {
          convertedObjs.push({
            itemId: itemUpdated.itemId,
            action: itemUpdated.action,
          });
        }
      });
    }
    return convertedObjs;
  }
  async function callApiToSbumitAssignment() {
    try {
      console.log("callApiToSbumitAssignment");
      let url = BASE_URL + "/action/pendingItems";
      let reqMethod = "POST";
      const headers = {
        Authorization: `Bearer ${getAuthToken()}`,
        "Content-Type": "Application/json",
      };

      //use only updated items
      const itemsUpdated = itemActionMap.filter((item) => item.action !== "");
      // console.log("itemsUpdated = ", itemsUpdated);
      const objToSubmit = {
        assignmentId: assignment.assignmentId,
        textItems: getTextItemsToSubmit(itemsUpdated),
        mediaItems: getMediaItemsToSubmit(itemsUpdated),
      };
      console.log("objTosubmit = ", objToSubmit);
      const response = await fetch(url, {
        headers,
        method: reqMethod,
        body: JSON.stringify(objToSubmit),
      });

      if (!response.ok) {
        if (response.status === 403) {
          console.log("got 403....");
          doLogout(dispatch, authActions);
          navigate("/login");
        } else {
          throw json(
            {
              message: "Could not submit items.",
            },
            {
              status: 500,
            }
          );
        }
      } else {
        window.alert("Items submitted successfully");
        dispatch(assignmentActions.assignmentSubmitted(null));
      }
    } catch (error) {
      window.alert("Error:", error);
    } finally {
      setIsLoading("");
    }
  }
  async function pickNewAssignment() {
    if (isLoading) {
      console.log("In PickAssignment, Loading in progress...doing nothing.");
      return;
    }
    if (assignment.itemsAssigned !== 0) {
      console.log("items already fetched...doing nothing.");
      return;
    }
    setIsLoading("true");
    console.log("picking new assignment...");
    await callApiToFetchNewAssignment();
  }
  async function submitAssignment() {
    if (isLoading) {
      console.log("In Submit, Loading in progress...doing nothing.");
      return;
    }
    if (isLoading || assignment.itemsAssigned === 0) {
      console.log("No itemAssigned to Submit...doing nothing.");
      return;
    }
    setIsLoading("true");
    console.log("submitting assignment...", itemActionMap);
    await callApiToSbumitAssignment();
  }
  //------------------------------------------------------------------
  function getTextItems() {
    if (assignment.itemsAssigned > 0) {
      console.log("text items length = ", assignment.textItems.length);
      if (assignment.textItems.length > 0) {
        return (
          <>
            {assignment.textItems.map((item, index) => (
              <TextItems
                key={item.itemId}
                item={item}
                updateActionOnItem={updateActionOnItem}
              />
            ))}
          </>
        );
      }
    }
  }
  function getMediaItems() {
    if (assignment.itemsAssigned > 0) {
      console.log("Media items length = ", assignment.mediaItems.length);
      if (assignment.mediaItems.length > 0) {
        return (
          <>
            {assignment.mediaItems.map((item, index) => (
              <MediaItems
                key={item.itemId}
                item={item}
                updateActionOnItem={updateActionOnItem}
              />
            ))}
          </>
        );
      }
    }
  }
  function getPickAssignmentButton() {
    if (assignment.itemsAssigned === 0) {
      return (
        <button
          className={commonStyles.grayButton}
          onClick={pickNewAssignment}
          type="button"
        >
          Pick Assignment
        </button>
      );
    }
  }
  function getSubmitButton() {
    if (assignment.itemsAssigned > 0) {
      return (
        <button
          className={commonStyles.purpleButton}
          onClick={submitAssignment}
          type="button"
        >
          Submit
        </button>
      );
    }
  }
  //------------------------------------------------------------------
  return (
    <div className={styles.dashboardContainer}>
      {isLoading && <Loader />}
      {/* <div>dashboard {auth.isAuthenticated ? "true" : "false"}</div> */}
      {getPickAssignmentButton()}
      {getTextItems()}
      {getMediaItems()}
      {getSubmitButton()}
      <br />
    </div>
  );
}

export default Dashboard;
