import { combineReducers } from "redux";
import authReducer from "./authSlice"; // Import your authSlice reducer
import userProfileReducer from "./userProfileSlice";
import assignmentReducer from "./assignmentSlice";

// Combine all reducers here
const rootReducer = combineReducers({
  auth: authReducer,
  userProfile: userProfileReducer,
  assignment: assignmentReducer,
  // Add other reducers if any
});

export default rootReducer;
