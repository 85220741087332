import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { authActions } from "../store/reducers/authSlice";
import { Loader } from "../components/UI/ErrorModal";
import {
  Form,
  json,
  redirect,
  useNavigate,
  useNavigation,
} from "react-router-dom";
import { isAPhoneNumber } from "../util/emailPhoneChecker";
import { BASE_URL } from "../Constants";
import styles from "./LoginPage.module.scss";
function LoginPage() {
  const navigation = useNavigation();
  const isSubmitting = navigation.state === "submitting";
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const navigate = useNavigate();
  useEffect(() => {
    //TODO THIS SHOULD NOT BE REQUIRED AS WE ARE ALREADY USING ROUTER LOADER
    if (isAuthenticated) {
      navigate("/dashboard");
    }
  }, [isAuthenticated, navigate]);

  const [username, setUsername] = useState("+918447899392");
  const [password, setPassword] = useState("welcome");

  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
    localStorage.setItem("phoneOrEmail", e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  return (
    <div className={styles.loginBg}>
      {isSubmitting && <Loader />}
      <h2 className={styles.pageTitle}>Login</h2>
      <br />
      <br />
      <Form method="post">
        <div>
          <label className={styles.fieldLabel} htmlFor="username">
            Username:
          </label>
          <br />
          <input
            className={styles.inputField}
            type="text"
            id="username"
            name="username"
            value={username}
            onChange={handleUsernameChange}
          />
        </div>
        <br />
        <div>
          <label className={styles.fieldLabel} htmlFor="password">
            Password:
          </label>
          <br />
          <input
            className={styles.inputField}
            type="password"
            id="password"
            name="password"
            value={password}
            onChange={handlePasswordChange}
          />
        </div>
        <br />
        <div>
          <button
            className={styles.loginPageSubmitBtn}
            type="submit"
            disabled={isSubmitting}
          >
            {isSubmitting ? "Submitting..." : "Submit"}
          </button>
        </div>
      </Form>
      {/* <p>
        <Link to="/forgotPassword">Forgot Password?</Link>
      </p> */}
    </div>
  );
}
export default LoginPage;

export async function action(request, params, dispatch) {
  console.log("login action is called.. request = ", request);
  const data = await request.formData();
  const phoneNumber = data.get("username").trim();

  if (!isAPhoneNumber(phoneNumber)) {
    return null; //alert will be shown while checking in isAPhoneNumber() and isAnEmail()
  }
  let url = BASE_URL + "/token/login";
  let loginData = {
    phoneNumber: phoneNumber,
    password: data.get("password").trim(),
  };
  try {
    const response = await fetch(url, {
      method: request.method,
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(loginData),
    });

    if (!response.ok) {
      throw json({ message: "Could not login." }, { status: 500 });
      //todo handle various status code here
    }

    const responseJson = await response.json();
    console.log("login response : ", responseJson);
    localStorage.setItem("token", responseJson.accessToken);
    await dispatch(authActions.loginSuccess(responseJson.accessToken));
    return redirect("/dashboard");
  } catch (e) {
    console.log("some error occurred: ", e);
    //todo handle error
  }
}
