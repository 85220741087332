import { NavLink } from "react-router-dom";
import classes from "./MainNavigation.module.scss";
import { useRef } from "react";

function MainNavigation() {
  const burgerCheckRef = useRef(null);
  console.log("in MainNavigation");
  function blankAreaClicked(e) {
    const targetStr = "" + e.target;
    // console.log(
    //   "targetStr=",
    //   targetStr,
    //   "e.target.value=",
    //   e.target.value,
    //   "burgerRef.current.checked=",
    //   burgerCheckRef.current.checked
    // );
    if (
      targetStr.includes("HTMLDivElement") &&
      burgerCheckRef.current.checked === true
    ) {
      //that means side blank area is clicked, so uncheck the burger checkbox
      burgerCheckRef.current.checked = false;
    }
  }
  return (
    <div className={classes.sideMenuContainer} data-testid="sideMenuContainer">
      <div className={classes.leftSection}>
        <pre> </pre>
      </div>
      <div onClick={blankAreaClicked} className={classes.sideMenuWrapper}>
        <label className={classes.hamburgerMenu} id="hamburgerMenu">
          <input ref={burgerCheckRef} type="checkbox" />
        </label>
        <aside className={classes.sidebar}>
          <nav>
            <ul className={classes.list}>
              <li>
                <NavLink
                  to="/dashboard"
                  className={({ isActive }) =>
                    isActive ? classes.active : undefined
                  }
                  end //to denote the ending of url path
                >
                  Dashboard
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/profile"
                  className={({ isActive }) =>
                    isActive ? classes.active : undefined
                  }
                >
                  Profile
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/logout"
                  className={({ isActive }) =>
                    isActive ? classes.active : undefined
                  }
                >
                  Logout
                </NavLink>
              </li>
            </ul>
          </nav>
        </aside>
      </div>
    </div>
  );
}
export default MainNavigation;
